/* eslint-disable */
import React from "react"
import Title from "../Title"
import styles from "../../css/team1.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const getTeam1 = graphql`
  query team1Image {
    team1Image: file(relativePath: { eq: "austin.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Team1 = () => {
  const { team1Image } = useStaticQuery(getTeam1)

  return (
    <section className={styles.team1}>
      <Title title="rivet" subtitle="team" />
      <div className={styles.team1Center}>
        <article className={styles.team1Img}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="team1 company" /> */}
            <Img
              fluid={team1Image.childImageSharp.fluid}
              alt="awesome landscape"
            />
          </div>
          <h4 className={styles.team1Info}>Austin Roberts</h4>
        </article>
      </div>
    </section>
  )
}

export default Team1
