/* eslint-disable */
import React from "react"
import Title from "../../Title"
import styles from "../../../css/calc.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Utils from "../../../utils"

const timeFactor = {
  second: 30*24*60*60,
  block: 30*24*60*60 / 12,
  day: 30,
  month: 1,
}

class Calc extends React.Component {
  constructor() {
    super()
    this.state = {
      requests: "",
      period: "second",
      prepay: false,
    }
  }
  render() {
    const handleChange = (e) => {
      let state = {};
      state[e.target.name] = e.target.value;
      console.log(state, e);
      this.setState(state)
    }
    const handleToggle = (e) => {
      let state = {};
      state[e.target.name] = !this.state[e.target.name];
      this.setState(state);
    }
    let requestimate = Math.max(0, parseInt(this.state.requests * timeFactor[this.state.period] / 100000) - 30);
    if(this.state.prepay) {
      let requests = this.state.requests * timeFactor[this.state.period];
      let price = 1 / 100000;
      if (requests < 1000000) { price = 1 / 100000 }
      else if (requests < 100000000) { price = .995 / 100000 }
      else if (requests < 1000000000) { price = .895 / 100000 }
      else { price = .845 / 100000 }
      requestimate = Math.ceil(Math.max(0, (price * this.state.requests * timeFactor[this.state.period]) - 30));
    }
    let message = "\u2060";
    if(requestimate >= 1000) {
      message = "That's a pretty big number. Give us a shout at concierge@rivet.cloud."
    }

    // TODO: Greg review language below DONE
    return (
      <section className={styles.calc}>
      <Title title="rivet" subtitle="requestimator" />
      <p className={styles.instructions}>Enter your anticipated request volume here to find out how much you'll save with Rivet:</p>
      <div className={styles.center}>
      <div>
      <input
      type="text"
      name="requests"
      value={this.state.requests}
      id="requests"
      onChange={handleChange}
      className={styles.formControl}
      placeholder="requests"
      />
      <span className={styles.instructions}>per</span>
      <select className={styles.period} name="period" value={this.state.period} onChange={handleChange}>
        <option value="second">second</option>
        <option value="block">block</option>
        <option value="day">day</option>
        <option selected value="month">month</option>
      </select>
      <div className={styles.requestimate}>&asymp; ${requestimate}.00 / month</div>
      <br />
      <input
      type="checkbox"
      name="prepay"
      id="prepay"
      onChange={handleToggle}
      checked={this.state.prepay}
      className={styles.prepayTick}
      height='20px'
      width='20px'
      /><label htmlFor="prepay" className={styles.instructions}> &nbsp; Apply DAI/ETH Discount</label>
      </div>
      <div className={styles.instructions}>{message}</div>
      </div>
      <AniLink onClick={Utils.eventRegistrar("sign-me-up-3")} fade to="/buidl" className="btn-white">
            Sign me up!
          </AniLink>
      </section>
    )
  }

}

export default Calc
