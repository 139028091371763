/* eslint-disable */
import React from "react"
import Title from "../Title"
import styles from "../../css/team4.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const getTeam4 = graphql`
  query team4Image {
    team4Image: file(relativePath: { eq: "sam.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Team4 = () => {
  const { team4Image } = useStaticQuery(getTeam4)

  return (
    <section className={styles.team4}>
      <div className={styles.team4Center}>
        <article className={styles.team4Img}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="team4 company" /> */}
            <Img
              fluid={team4Image.childImageSharp.fluid}
              alt="awesome landscape"
            />
          </div>
          <h4 className={styles.team4Info}>Sam Johnston</h4>
        </article>
      </div>
    </section>
  )
}

export default Team4
