/* eslint-disable */
import React from "react"
import Title from "../Title"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "../../css/privacy.module.css"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Utils from "../../utils"
import Img from "gatsby-image"
const getPrivacy = graphql`
  query privacyImage {
    privacyImage: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Privacy = () => {
  const { privacyImage } = useStaticQuery(getPrivacy)

  return (
    <section className={styles.privacy}>
      <Title title="Ironclad" subtitle="privacy" />
      <div className={styles.privacyCenter}>
          <p>At Rivet, we believe that your data (and your customers' data) should never be involuntarily relinquished to anyone.<br/><br/>That's why we only retain the minimum amount of information necessary to operate our service for the minimum amount of time necessary.<br/><br/>And we will never sell or willingly share your data with anyone, period.</p>
          <div className={styles.buttonContainer}>
          <AniLink fade to="/privacypolicy" className="btn-white">
            View our privacy policy.
          </AniLink>
          <AniLink fade onClick={Utils.eventRegistrar("sign-me-up-1")} to="/buidl" className="btn-white">
            Sign up now.
          </AniLink>
          </div>
      </div>
    </section>
  )
}

export default Privacy
