/* eslint-disable */
import React from "react"
import Layout from "../components/Layout"
import LoadRecaptcha from "../components/LoadRecaptcha"
import Banner from "../components/Banner"
import Values from "../components/Home/Values"
import Product from "../components/Home/Product"
import Product2 from "../components/Home/Product2"
import Plans from "../components/Home/Plans"
import Product3 from "../components/Home/Product3"
import StyledHero from "../components/StyledHero"
import Pricing from "../components/Home/Pricing"
import PartnersCustomers1 from "../components/Home/PartnersCustomers1"
import PartnersCustomers2 from "../components/Home/PartnersCustomers2"
import PartnersCustomers3 from "../components/Home/PartnersCustomers3"
import PartnersCustomers4 from "../components/Home/PartnersCustomers4"
import PartnersCustomers5 from "../components/Home/PartnersCustomers5"
import Support from "../components/Home/Support"
import Team1 from "../components/Home/Team1"
import Team2 from "../components/Home/Team2"
import Team3 from "../components/Home/Team3"
import Team4 from "../components/Home/Team4"
import Privacy from "../components/Home/Privacy"
import Calc from '../components/Home/Calc/Calc.js'
import logo from "../images/logo_white.png"
import styles from '../css/polish.module.css'
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Helmet } from "react-helmet"
import Utils from "../utils"

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Rivet | Ethereum Blockchain and Node Services</title>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@R_I_V_E_T" />
      <meta name="twitter:creator" content="@open_relay" />
      <meta name="twitter:title" content="The best blockchain gateway on 🌎ᵍᵐ" />
      <meta name="twitter:description" content="Cloud-based Ethereum and Ethereum Classic APIs that truly just work." />
      <meta name="twitter:image" content="https://rivet.cloud/static/5a4a380358fba4c24350bee563fcc195/2d640/rivet_logo.png" />

      <meta name="description" content="Stop overpaying for your Ethereum blockchain API. Get the strongest privacy with Rivet and unlock 3 million free requests when you sign-up." />
    </Helmet>
    <LoadRecaptcha />
    <StyledHero home="true" img="none">
      <Banner
        title="The best blockchain gateway on 🌎ᵍᵐ"
        info="Cloud-based Ethereum APIs that truly just work."
      >
        <AniLink fade onClick={Utils.eventRegistrar("get-started-free-hero")} to="/buidl" className="btn-white">
          Get Started Free
        </AniLink>
        <img src={logo} alt="rivet logo" className={styles.logoSVG2} />
      </Banner>
    </StyledHero>
    <Values />
    <Product />
    <Product3 />
    <Plans />
    <Privacy />
    <Support />
    <Pricing />
    <Calc />
    <Product2 />
    <Team1 />
    <Team2 />
    <Team3 />
    <Team4 />
    <PartnersCustomers1 />
    <PartnersCustomers2 />
    <PartnersCustomers3 />
    <PartnersCustomers4 />
    <PartnersCustomers5 />
  </Layout>
)
export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
