/* eslint-disable */
import React from "react"
import Title from "../Title"
import styles from "../../css/team3.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const getTeam3 = graphql`
  query team3Image {
    team3Image: file(relativePath: { eq: "beth.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Team3 = () => {
  const { team3Image } = useStaticQuery(getTeam3)

  return (
    <section className={styles.team3}>
      <div className={styles.team3Center}>
        <article className={styles.team3Img}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="team3 company" /> */}
            <Img
              fluid={team3Image.childImageSharp.fluid}
              alt="awesome landscape"
            />
          </div>
          <h4 className={styles.team3Info}>Beth Van Horn</h4>
        </article>
      </div>
    </section>
  )
}

export default Team3
