/* eslint-disable */
import React from "react"
import styles from "../../css/product3.module.css"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const getProduct3 = graphql`
  query productImage3 {
    productImage3: file(relativePath: { eq: "logo_white.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Product3 = () => {
  const { productImage3 } = useStaticQuery(getProduct3)

  return (<>
    <div className={styles.product}>
    <div className={styles.headoverContainer}>
      <h2 className={styles.productHeading}>Plans and Features</h2>
      <div className={styles.break}></div>
      <p className={styles.productOverview}>Whether you're BUIDLing at a hackathon or running a dapp that's bigger than Beeple—we've got you covered.</p>
    </div>
        <article className={styles.productImg}>
          <div className={styles.imgContainer}>
              <Img
                fluid={productImage3.childImageSharp.fluid}
                alt="Node infrastructure is complex problem disguised as a simple one."
              />
            </div>
        </article>
        </div>
    </>

  )
}

export default Product3
