/* eslint-disable */
import React from "react"
import Title from "../Title"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "../../css/partnerscustomers5.module.css"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const getPartnersCustomers5 = graphql`
  query partnerscustomers5Image {
    partnerscustomers5Image: file(relativePath: { eq: "partnerscustomers5.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const PartnersCustomers5 = () => {
  const { partnerscustomers5Image } = useStaticQuery(getPartnersCustomers5)

  return (
    <section className={styles.partnerscustomers5}>
      <Title title="" subtitle=""/>
      <div className={styles.partnerscustomers5Center}>
        <article className={styles.partnerscustomers5Img}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="partnerscustomers5 company" /> */}
            <a href="https://etclabs.org/" target="_blank"><Img
              fluid={partnerscustomers5Image.childImageSharp.fluid}
              alt="ETC Labs Banner"
            /></a>
          </div>
        </article>
        <article className={styles.partnerscustomers5Info}>
          <h4>ETC Labs</h4>
          <ul>
          <li><strong>Invests in up to 25 ETC blockchain projects annually that contribute to sustaining a robust ecosystem.</strong></li>
          <li><strong>Fosters partnerships with organizations and institutions to address fundamental challenges developing innovative technologies.</strong></li>
          <li><strong>Check out<a href='https://etclabs.org/' target="__blank" >ETC Labs' website</a> to learn more.</strong></li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default PartnersCustomers5
