/* eslint-disable */
import React from "react"
import Title from "../Title"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "../../css/partnerscustomers1.module.css"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const getPartnersCustomers1 = graphql`
  query partnerscustomers1Image {
    partnerscustomers1Image: file(relativePath: { eq: "partnerscustomers1.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const PartnersCustomers1 = () => {
  const { partnerscustomers1Image } = useStaticQuery(getPartnersCustomers1)

  return (
    <section className={styles.partnerscustomers1}>
      <h4 className={styles.bigHeading}>Partners and Customers</h4>
      <Title title="" subtitle=""/>
      <div className={styles.partnerscustomers1Center}>
        <article className={styles.partnerscustomers1Img}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="partnerscustomers1 company" /> */}
            <a href="https://myetherwallet.com" target="_blank"><Img
              fluid={partnerscustomers1Image.childImageSharp.fluid}
              alt="MEW Banner"
            /></a>
          </div>
        </article>
        <article className={styles.partnerscustomers1Info}>
          <h4>MyEtherWallet</h4>
          <ul>
          <li><strong>One of Ethereum's most trusted and long-running wallets.</strong></li>
          <li><strong>Serves customers via desktop, iOS and Android.</strong></li>
          <li><strong>Check them out at<a href="https://myetherwallet.com" target="_blank">MyEtherWallet.com</a>.</strong></li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default PartnersCustomers1
