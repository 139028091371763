import React from "react"
import { FaEthereum, FaUserShield, FaDna, FaVolumeUp, FaMoneyBillAlt } from "react-icons/fa"

export default [
  {
    icon: <FaEthereum />,
    title: "Total Simplicity",
    text: "From signup to scale-up, Rivet makes node infrastructure easy.",
  },
  {
    icon: <FaVolumeUp />,
    title: "Infinite Scalability",
    text: "No matter your request volume, Rivet scales to meet your needs.",
  },
  {
    icon: <FaUserShield />,
    title: "Peerless Privacy",
    text: "Rivet will never share or sell your customers’ data to 3rd parties.",
  },
  {
    icon: <FaMoneyBillAlt />,
    title: "Unparalleled Value",
    text: "With Rivet, you can count on the biggest bang for the fewest bucks.",
  },
]
