/* eslint-disable */
import React from "react"
import Title from "../Title"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "../../css/support.module.css"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Utils from "../../utils"
import Img from "gatsby-image"
const getSupport = graphql`
  query supportImage {
    supportImage: file(relativePath: { eq: "rivet_support.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Support = () => {
  const { supportImage } = useStaticQuery(getSupport)

  return (
    <section className={styles.support}>
      <Title title="" subtitle="enterprise-grade support" className={styles.titleText} />
      <div className={styles.supportCenter}>
        <article className={styles.supportImg}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="support company" /> */}
            <Img
              fluid={supportImage.childImageSharp.fluid}
              alt="awesome landscape"
            />
          </div>
        </article>
        <article className={styles.supportInfo}>
          <ul>
          <li>Up-to-date, easy-to-navigate documentation.</li>
          <li>Access to real human beings who care about your success.</li>
          <li>99.95% Uptime guaranteed.</li>
          </ul>
          <a href="https://rivet.cloud/docs" className="btn-white">
            Docs
          </a>
          <AniLink paintDrip to="/faq" hex="#070707" className="btn-white">
            FAQ
          </AniLink>
          <AniLink paintDrip onClick={Utils.eventRegistrar("sign-me-up-2")} to="/buidl" hex="#070707" className="btn-white">
            Sign me up!
          </AniLink>
        </article>
      </div>
    </section>
  )
}

export default Support
