/* eslint-disable */
import React from "react"
import Title from "../Title"
import styles from "../../css/product.module.css"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const getProduct = graphql`
  query productImage {
    productImage: file(relativePath: { eq: "with_rivet.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Product = () => {
  const { productImage } = useStaticQuery(getProduct)

  return (<>
    <div className={styles.headoverContainer}>
      <h2 className={styles.productHeading}>Node Infrastructure Made Easy</h2>
      <div className={styles.break}></div>
      <p className={styles.productOverview}>Spend more time doing what matters most to you and your team.</p>
    </div>
        <article className={styles.productImg}>
          <div className={styles.imgContainer}>
              <Img
                fluid={productImage.childImageSharp.fluid}
                alt="Node infrastructure is complex problem disguised as a simple one."
              />
            </div>
        </article>
    </>

  )
}

export default Product
