/* eslint-disable */
import React from "react"
import Title from "../Title"
import styles from "../../css/team2.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const getTeam2 = graphql`
  query team2Image {
    team2Image: file(relativePath: { eq: "greg.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Team2 = () => {
  const { team2Image } = useStaticQuery(getTeam2)

  return (
    <section className={styles.team2}>
      <div className={styles.team2Center}>
        <article className={styles.team2Img}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="Team2 company" /> */}
            <Img
              fluid={team2Image.childImageSharp.fluid}
              alt="awesome landscape"
            />
          </div>
          <h4 className={styles.team2Info}>Greg Lang</h4>
        </article>
      </div>
    </section>
  )
}

export default Team2
