/* eslint-disable */
import React from "react"
import Title from "../Title"
import styles from "../../css/pricing.module.css"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const getPricing = graphql`
  query pricingImage {
    pricingImage: file(relativePath: { eq: "rivet_pricing.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Pricing = () => {
  const { pricingImage } = useStaticQuery(getPricing)

  return (
    <section className={styles.pricing}>
      <Title title="human-readable" subtitle="pricing" />
      <div className={styles.pricingCenter}>
        <article className={styles.heroContainer}>
          <h1 className={styles.pricingInfo}><span className={styles.pricingHero}>3M</span><div className={styles.slashReq}>free requests/mo*</div></h1>
          <h1 className={styles.pricingInfo}><span className={styles.pricingHero}>$1</span><div className={styles.slashReq}>/100,000 requests**</div></h1>
        </article>
        <h5 className={styles.pricingSubtext}>*Signup with valid email address required for free requests.<br/><br/>**Up to 15% off request packages purchased using DAI or ETH (no email address required).</h5>
     </div>
    </section>
  )
}

export default Pricing
