/* eslint-disable */
import React from "react"
import Title from "../Title"
import styles from "../../css/values.module.css"
import values from "../../constants/values"
const Values = () => {
  return (
    <section className={styles.values}>
      <Title title="why" subtitle="rivet?" />
      <div className={styles.center}>
        {values.map((item, index) => {
          return (
            <article key={index} className={styles.value}>
              <span>{item.icon}</span>
              <h4>{item.title}</h4>
              <p>{item.text}</p>
            </article>
          )
        })}
      </div>
    </section>
  )
}

export default Values
