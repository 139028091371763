/* eslint-disable */
import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "../../css/product2.module.css"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Utils from "../../utils"
const getProduct2 = graphql`
  query productImage2 {
    productImage2: file(relativePath: { eq: "rivet_architecture.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Product2 = () => {
  const { productImage2 } = useStaticQuery(getProduct2)

  return (
    <section className={styles.product}>
      <div className={styles.productCenter}>
        <article className={styles.productImg}>
        <div className={styles.imgContainer}>
            <Img
              fluid={productImage2.childImageSharp.fluid}
              alt="awesome landscape"
            />
          </div>
        </article>
        <h4 className={styles.productHeading}>Under the hood—what makes Rivet special.</h4>
        <article className={styles.productInfo}>
          <p>
            Unlike other services, Rivet scales via streaming replication. How does it work?<br/><br/>1. Master nodes sync and validate block data.<br/>2. Masters write to disk and a Kafka stream.<br/>3. Replicas write streamed data to disk.<br/>4. Replicas respond to Rivet API requests.
          </p>
          <p>TLDR? Streaming replication is why Rivet just works better. But don't take our word for it—try it out and see for yourself.</p>
          <AniLink fade onClick={Utils.eventRegistrar("sign-up-now-1")} to="/buidl" className="btn-white">
            sign up now
          </AniLink>
        </article>
      </div>
    </section>

  )
}

export default Product2
