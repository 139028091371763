/* eslint-disable */
import React from "react"
import Title from "../Title"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "../../css/plans.module.css"
import plans from "../../constants/plans"
import Utils from "../../utils"
const Plans = () => {
  return (
    <section className={styles.plans}>
      <div className={styles.center}>
        {plans.map((item, index) => {
          return (
            <article key={index} className={styles.plan}>
              <span>{item.icon}</span>
              <h4>{item.title}</h4>
              <div className={styles.detailsContainer}><div className={styles.detailsInner}><p>{item.text}</p><div className={styles.break}></div>
              <h5>Requirements</h5>
              <p>{item.requirements}</p>
              <h5>Pricing</h5>
              <p>{item.price}</p></div></div>
                <AniLink onClick={Utils.eventRegistrar(item.event)} paintDrip to="/buidl" hex="#070707" className={styles.planButton}>
                  {item.cta}
                </AniLink>
            </article>
          )
        })}
      </div>
    </section>
  )
}

export default Plans
