/* eslint-disable */
import React from "react"
import Title from "../Title"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "../../css/partnerscustomers2.module.css"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const getPartnersCustomers2 = graphql`
  query partnerscustomers2Image {
    partnerscustomers2Image: file(relativePath: { eq: "partnerscustomers2.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const PartnersCustomers2 = () => {
  const { partnerscustomers2Image } = useStaticQuery(getPartnersCustomers2)

  return (
    <section className={styles.partnerscustomers2}>
      <Title title="" subtitle=""/>
      <div className={styles.partnerscustomers2Center}>
        <article className={styles.partnerscustomers2Img}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="partnerscustomers2 company" /> */}
            <a href="https://drapergorenholm.com" target="_blank"><Img
              fluid={partnerscustomers2Image.childImageSharp.fluid}
              alt="OpenRelay Banner"
            /></a>
          </div>
        </article>
        <article className={styles.partnerscustomers2Info}>
          <h4>Draper Goren Holm</h4>
          <ul>
          <li><strong>Blockchain venture studio.</strong></li>
          <li><strong>Focused on amplifying the best blockchain startups.</strong></li>
          <li><strong>Established relationship with Rivet in July 2020, announced in early August 2020.</strong></li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default PartnersCustomers2
