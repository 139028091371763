/* eslint-disable */
import React from "react"
import Title from "../Title"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "../../css/partnerscustomers4.module.css"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const getPartnersCustomers4 = graphql`
  query partnerscustomers4Image {
    partnerscustomers4Image: file(relativePath: { eq: "partnerscustomers4.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const PartnersCustomers4 = () => {
  const { partnerscustomers4Image } = useStaticQuery(getPartnersCustomers4)

  return (
    <section className={styles.partnerscustomers4}>
      <Title title="" subtitle=""/>
      <div className={styles.partnerscustomers4Center}>
        <article className={styles.partnerscustomers4Img}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="partnerscustomers4 company" /> */}
            <a href="https://stateofthedapps.com" target="_blank"><Img
              fluid={partnerscustomers4Image.childImageSharp.fluid}
              alt="State of the Dapps Banner"
            /></a>
          </div>
        </article>
        <article className={styles.partnerscustomers4Info}>
          <h4>State of the Dapps</h4>
          <ul>
          <li><strong>The most popular place to explore the latest dapps built on Ethereum.</strong></li>
          <li><strong>Publishes statistics relevant to ecosystem and blockchain growth.</strong></li>
          <li><strong>Check out<a href='https://www.stateofthedapps.com/dapps/rivet' target="__blank" >our listing</a> and exlore many more at<a href="https://stateofthedapps.com" target="_blank">stateofthedapps.com</a>.</strong></li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default PartnersCustomers4
