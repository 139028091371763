/* eslint-disable */
import React from "react"
import Title from "../Title"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "../../css/partnerscustomers3.module.css"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const getPartnersCustomers3 = graphql`
  query partnerscustomers3Image {
    partnerscustomers3Image: file(relativePath: { eq: "partnerscustomers3.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const PartnersCustomers3 = () => {
  const { partnerscustomers3Image } = useStaticQuery(getPartnersCustomers3)

  return (
    <section className={styles.partnerscustomers3}>
      <Title title="" subtitle=""/>
      <div className={styles.partnerscustomers3Center}>
        <article className={styles.partnerscustomers3Img}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="partnerscustomers3 company" /> */}
            <a href = "https://temporal.cloud" target="_blank"><Img
              fluid={partnerscustomers3Image.childImageSharp.fluid}
              alt="Temporal Banner"
            /></a>
          </div>
        </article>
        <article className={styles.partnerscustomers3Info}>
          <h4>Temporal</h4>
          <ul>
          <li><strong>Powering Rivet's forthcoming integration with IPFS.</strong></li>
          <li><strong>Provides cutting edge, easy-to-use, secure, and cost-effective IPFS APIs.</strong></li>
          <li><strong>Learn more about Temporal's awesome service at<a href="https://temporal.cloud" target="_blank">temporal.cloud.</a></strong></li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default PartnersCustomers3
