import React from "react"
import { FaCloud, FaSnowplow, FaBolt, FaEthereum, FaInfinity } from "react-icons/fa"

export default [
  {
    icon: <FaCloud />,
    title: "rivet.cloud",
    text: "Our base plan gives you access to Rivet high-availability RPC and WS endpoints for all supported networks: Ethereum and Ethereum Classic mainnets, Goerli, Ropsten, and Rinkeby testnets, and the Ethereum 2.0 beacon chain.",
    requirements: "A Rivet account.",
    price: "$0.00.",
    cta: "Sign me up",
    event: "sign-me-up-1",
  },
  {
    icon: <FaSnowplow />,
    title: "buidl",
    text: "The BUIDL plan gives you 3,000,000 free requests a month.",
    requirements: "A verified email address.",
    price: "$0.00.",
    cta: "Sign up with Email",
    event: "sign-up-with-email",
  },
  {
    icon: <FaInfinity />,
    title: "scael",
    text: "The SCAEL plan enables you to make an unlimited number of requests to your endpoints. At the end of the month, you pay only for what you use.",
    requirements: "A valid credit/debit card.",
    price: "$1 per 100,000 requests.",
    cta: "Get started free",
    event: "get-started-free",
  },
  {
    icon: <FaBolt />,
    title: "bolt",
    text: "As a Bolt member, you get access to unique benefits, perks, and powerful new features—starting with an index that lets you access transaction data without limitations. (Note: If you buy credits with DAI or ETH, you automatically recieve time-limited access to Bolt membership features.)",
    requirements: "A valid credit/debit card or Ethereum wallet.",
    price: "$25 per month",
    cta: "Let's do this!",
    event: "lets-do-this",
  },
  {
    icon: <FaEthereum />,
    title: "crypto",
    text: "Our CRYPTO plan gives you the ability to pre-purchase request capacity using DAI or ETH. Credits purchased via this plan also give you time-limited access to Bolt. Additionally, credits purchased in larger volumes can recieve a discount up to 15%.",
    requirements: "A connected Ethereum wallet.",
    price: "$1 per 100,000 requests - volume discount (if applicable).",
    cta: "Sign up with Web3",
    event: "sign-up-with-web3",
  },
]
